/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { NUMBERS_ONLY_REGEX, PID_LENGTH } from 'forms/validations/constants';
import { useTranslation } from 'next-i18next';
import { Box, Form, Typography } from 'ui';
import { object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  personalId: string;
}

export const PersonalIdStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { goToNextStep, getCustomerData, setPersonalId, navigateWithRedirect } = useCreateCustomer();

  const { personalId } = getCustomerData();

  const resolver = yupResolver(
    object({
      personalId: string()
        .required(t('steps.personalId.fields.personalId.required'))
        .matches(NUMBERS_ONLY_REGEX, { message: t('steps.personalId.fields.personalId.onlyNumbersField') })
        .min(PID_LENGTH, t('steps.personalId.fields.personalId.fieldLength', { length: PID_LENGTH }))
        .max(PID_LENGTH, t('steps.personalId.fields.personalId.fieldLength', { length: PID_LENGTH })),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: { personalId },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    trackTagManagerEvent(OnboardingEvents.personalId);
    setPersonalId(values.personalId);

    navigateWithRedirect(goToNextStep);
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.personalId.title')}</StepTitle>

            <Typography variant="subtitle2">{t('steps.personalId.description')}</Typography>
          </Box>

          <TextInput
            required
            margin="normal"
            fullWidth
            label={t('steps.personalId.fields.personalId.label')}
            placeholder={t('steps.personalId.fields.personalId.placeholder')}
            name="personalId"
            autoFocus
            shrink
            data-testid="personal-id"
          />
        </Box>

        <ContinueButton data-testid="personal-id-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
