import { BlenderEvents, trackTagManagerEvent } from 'firebase-client';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { ListPageTitle, SearchControl, useResolution } from 'ui';

import { LayoutSwitcher, ScrollableViewWithData } from '~/ui';

import { useCustomers } from '../../hooks/useCustomers/useCustomers';
import { CustomersList } from './CustomersList';

export const UserCustomers = () => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>();

  const handleSearch = (q: string) => {
    trackTagManagerEvent(BlenderEvents.customerSearch);
    setSearchQuery(q);
  };

  const { isTablet } = useResolution();

  return (
    <>
      <ListPageTitle
        title={t('nav.customers')}
        desktopActions={<LayoutSwitcher />}
        actions={
          <SearchControl
            name={t('customers:search')}
            placeholder={t('customers:search')}
            onSearch={handleSearch}
            popoverPosition={isTablet ? 'right' : 'left'}
          />
        }
      />

      <ScrollableViewWithData
        useDataHook={useCustomers}
        hookOptions={{ filterBy: { search: searchQuery } }}
        ListComponent={CustomersList}
      />
    </>
  );
};
