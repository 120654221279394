/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { Autocomplete, FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { Box, Form } from 'ui';
import { object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { splitLegalEntityName } from '~/customer/utils';
import { ContinueButton, StepTitle } from '~/ui';

export interface CompanyTypeAutocomplete {
  value: string;
  label: string;
}
interface FormValues {
  legalEntityName: string;
  companyType: CompanyTypeAutocomplete;
}

export const COMPANY_TYPES: CompanyTypeAutocomplete[] = [
  { label: 'ЕТ - Едноличен търговец', value: 'ЕТ' },
  {
    label: 'ЕООД - Еднолично дружество с ограничена отговорност',
    value: 'ЕООД',
  },
  { label: 'ООД - Дружество с ограничена отговорност', value: 'ООД' },
  { label: 'ЕАД - Еднолично акционерно дружество', value: 'ЕАД' },
  { label: 'АД - Акционерно дружество', value: 'АД' },
  { label: 'ЗП - Земеделски производител', value: 'ЗП' },
  { label: 'Сдружение', value: 'СДРУЖЕНИЕ' },
  { label: 'Фондация', value: 'ФОНДАЦИЯ' },
  { label: 'Кооперация', value: 'КООПЕРАЦИЯ' },
  { label: 'Народно Читалище', value: 'НАРОДНО ЧИТАЛИЩЕ' },
  { label: 'СД - Събирателно дружество', value: 'СД' },
];

export const LegalEntityNameStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { setCurrentStep, getCustomerData, setLegalEntityName, navigateWithRedirect } = useCreateCustomer();

  const { legalEntityName } = getCustomerData();

  const { companyType, name: companyName } = splitLegalEntityName(legalEntityName);
  const companyTypeLabel = COMPANY_TYPES.find((type) => type.value === companyType)?.label;

  const resolver = yupResolver(
    object({
      companyType: object({
        label: string().required(),
        value: string().required(),
      }).required(t('steps.legalEntityName.fields.companyType.required')),
      legalEntityName: string().required(t('steps.legalEntityName.fields.legalEntityName.required')),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      companyType: { label: companyTypeLabel || '', value: companyType || '' },
      legalEntityName: companyName,
    },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (name) => {
    trackTagManagerEvent(OnboardingEvents.companyName);
    setLegalEntityName(`${name.legalEntityName.toUpperCase()} ${name.companyType.value}`);

    navigateWithRedirect(() => setCurrentStep('businessRepresentative1'));
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.name.title')}</StepTitle>
          </Box>

          <Box display="flex" flexDirection="column" gap={3}>
            <TextInput
              required
              margin="normal"
              fullWidth
              label={t('steps.legalEntityName.fields.legalEntityName.label')}
              placeholder={t('steps.legalEntityName.fields.legalEntityName.placeholder')}
              name="legalEntityName"
              autoFocus
              shrink
              data-testid="legal-entity-name"
            />

            <Autocomplete<CompanyTypeAutocomplete>
              label={t('steps.legalEntityName.fields.companyType.label')}
              placeholder={t('steps.legalEntityName.fields.companyType.placeholder')}
              name="companyType"
              required
              options={COMPANY_TYPES}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              shrink
              data-testid="company-type"
            />
          </Box>
        </Box>

        <ContinueButton data-testid="legal-entity-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
