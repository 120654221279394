import { useTranslation } from 'next-i18next';
import { CustomerCard as CustomerCardUI } from 'ui';

import { Customer, getAddress } from '~/data';
import { LinkContainer } from '~/ui';

import getCustomerPath from '../../helpers/getCustomerPath';

export const CustomerCard = ({
  customer,
  onClick,
}: {
  customer: Omit<Customer, 'clientId' | 'vatNo'>;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  const { name, customerId, address, representatives } = customer;

  return (
    <LinkContainer href={getCustomerPath(customer)} key={customerId} onClick={onClick}>
      <CustomerCardUI
        data-testid="customer-card"
        title={t('customers:id', { id: customerId })}
        subtitle={name}
        address={getAddress(address)}
        representativesLabel={t('customers:representatives')}
        representatives={representatives?.split(', ')}
      />
    </LinkContainer>
  );
};
