/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { Checkbox, FormProvider, SubmitHandler, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { Box, Form } from 'ui';
import { boolean, object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { ContinueButton, StepTitle } from '~/ui';

import { NameInputs } from './NameInputs';

interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  addRepresentative?: boolean;
}

const REPRESENTATIVE_INDEX = 0;

export const BusinessRepresentative1Step = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData, setRepresentatives, setCurrentStep, navigateWithRedirect, getHistory } = useCreateCustomer();

  const { representatives } = getCustomerData();

  const { firstName, middleName, lastName } = representatives[REPRESENTATIVE_INDEX]
    ? representatives[REPRESENTATIVE_INDEX]
    : {
        firstName: '',
        lastName: '',
        middleName: '',
      };

  const resolver = yupResolver(
    object({
      addRepresentative: boolean(),
      firstName: string().required(t('steps.businessRepresentative.fields.firstName.required')),
      lastName: string().required(t('steps.businessRepresentative.fields.lastName.required')),
      middleName: string().required(t('steps.businessRepresentative.fields.middleName.required')),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      addRepresentative: false,
      firstName,
      lastName,
      middleName,
    },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    trackTagManagerEvent(OnboardingEvents.representative);

    const newRepresentatives = !representatives[REPRESENTATIVE_INDEX]
      ? [
          ...representatives,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
          },
        ]
      : representatives;

    if (representatives[REPRESENTATIVE_INDEX]) {
      newRepresentatives[REPRESENTATIVE_INDEX] = {
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
      };
    }

    setRepresentatives(newRepresentatives, REPRESENTATIVE_INDEX + 1);

    const history = getHistory();

    // get the last step before the current one
    // subtract 2 because the current step is already in the history
    const lastStep = history[history.length - 2];

    if (values.addRepresentative || (lastStep === 'legalEntityName' && representatives.length === 2)) {
      setCurrentStep('businessRepresentative2');
      return;
    }

    navigateWithRedirect(() => setCurrentStep('address'));
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.businessRepresentative.title')}</StepTitle>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <NameInputs translationNamespace="businessRepresentative" />
          </Box>

          {representatives.length < 2 && (
            <Checkbox
              name="addRepresentative"
              label={t('steps.businessRepresentative.fields.addRepresentative.label')}
              data-testid="add-representative-checkbox"
            />
          )}
        </Box>

        <ContinueButton data-testid="business-representative1-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
