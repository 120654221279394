import { Box, Typography } from 'ui';

type MaintenanceMessage = {
  message1: string;
  message2: string | null;
  message3: string | null;
} | null;

const getMaintenanceMessage = (): MaintenanceMessage => {
  const maintenanceMessage = process.env.NEXT_PUBLIC_MALFUNCTION_MESSAGE || process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE;

  if (!maintenanceMessage) {
    return null;
  }

  try {
    return JSON.parse(maintenanceMessage);
  } catch (error) {
    return null;
  }
};

export const MaintenanceBanner = () => {
  const maintenanceMessage = getMaintenanceMessage();

  if (!maintenanceMessage) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'primary',
        display: 'flex',
        justifyContent: 'center',
      }}
      mb={2}
    >
      <Box bgcolor="notifications.orange" p={2} borderRadius={5}>
        <Typography color="black" variant="body2">
          {maintenanceMessage.message1}
        </Typography>

        {maintenanceMessage.message2 && (
          <Typography color="black" variant="body2">
            {maintenanceMessage.message2}
          </Typography>
        )}

        {maintenanceMessage.message3 && (
          <Typography color="black" variant="body2">
            {maintenanceMessage.message3}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
